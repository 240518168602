@keyframes shrink-text {
  0% {
    @apply w-10;
  }

  1% {
    @apply w-auto;
  }

  100% {
    @apply text-2xl w-auto;
  }
}

@keyframes shrink-sub {
  to {
    @apply text-sm sm:text-base mt-0;
  }
}

@keyframes shrink-bar {
  to {
    @apply p-3;
  }
}

@media not print {
  .shrink {
    animation: shrink-bar 0.5s forwards;
  }
  .shrink h1 {
    animation: shrink-text 0.3s forwards;
  }
  .shrink h2 {
    animation: shrink-sub 0.3s forwards;
  }
}
